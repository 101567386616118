import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/Layout'

const IndexPage = () => (
  <Layout>
    <h1>Check One Foundation</h1>
    <h3>Working with local communities</h3>
    <p>
      The Check One Foundation is a non-governmental, non-political, not-for-profit organisation.
      We work with urban and rural communities to help develop and execute their community development 
      plans. Working through the pre-existing local governance structure (e.g. community associations 
      or the traditional system) our projects always start off from the priorities and plans of the 
      local communities. We focus on project structure, harmonization and key perspectives with the 
      aim to bring the projects to fruition and delivering on the objectives set by the communities.
    </p>
    <p>
      We bring a strong results-oriented approach to each project and believe that each project 
      should be defined the work required to achieve its objectives. We also believe that each 
      community’s capacity for self-determination in executing development projects is a critical 
      aspect that needs to be protected each step of the way.
    </p>
    <ul>
      <li>Health and sanitation – the health of community members is fundamental to their ability to conduct economic activities and participate in the development of their community.</li>
      <li>Youth focus – each projects attempts to maximize the participation on all levels of the communities youth.</li>
      <li>Gender perspective – each project contains a clear gender perspective with relevancy to the community and project objectives.</li>
      <li>Environmental protection and natural resource management – each project considers environmental protections and natural resource management is an often recurring element, especially in rural projects.</li>
      <li>Peace-building and reconciliation – all conflict-resolution is done while keeping in mind the long-term development of the community and also its history.</li>
    </ul>
  </Layout>
)

export default IndexPage
